import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { ClientDashboard } from '@memberspot/shared/model/memberarea';

export interface ClientDashboardState
  extends EntityState<ClientDashboard, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clientdashboards' })
export class ClientDashboardStore extends EntityStore<ClientDashboardState> {
  constructor() {
    super();
  }
}
